import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Text, useColorModeValue, InputGroup, InputLeftElement, IconButton, Input,  } from '@chakra-ui/react';
import { CheckIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import useWindowDimensions from "helpers/screensize";
import DataTable, { createTheme } from 'react-data-table-component';
import { useMainStore } from "data";
import { isEmpty } from "lodash";
import DataGrid, {
    Column,
    Paging,
    SearchPanel,
    Selection,
    Sorting,
    Pager, Editing
} from 'devextreme-react/data-grid';

createTheme(
	'ddark',
	{
		text: {
			primary: "#fff",
			secondary: '#fff',
		},
		background: {
			default: '#2B323B',
		},
		context: {
			background: '#cb4b16',
			text: '#FFFFFF',
		},
		divider: {
			default: '',
		},
		button: {
			default: '#2aa198',
			hover: 'rgba(0,0,0,.08)',
			focus: 'rgba(255,255,255,.12)',
			disabled: 'rgba(255, 255, 255, .34)',
		},
		sortFocus: {
			default: '#2aa198',
		},
		striped: {
			default: 'rgba(255, 255, 255, .05)',
			text: 'rgba(255,255,255)',
		},
	}
);

createTheme(
	'llight',
	{
		text: {
			primary: "#000",
			secondary: '#000',
		},
		background: {
			default: '#F0F2F4',
		},
		context: {
			background: '#cb4b16',
			text: '#FFFFFF',
		},
		divider: {
			default: '',
		},
		button: {
			default: '#2aa198',
			hover: 'rgba(0,0,0,.08)',
			focus: 'rgba(255,255,255,.12)',
			disabled: 'rgba(255, 255, 255, .34)',
		},
		sortFocus: {
			default: '#2aa198',
		},
	},
);

const Page = (props) => {
	const accountsStore = useMainStore(state => state.accounts)
	const deleteAppAccount = useMainStore(state => state.deleteAppAccount)
	const { height } = useWindowDimensions();
	const listHoverColor = useColorModeValue("gray.700","gray.100");
	let inputBg = useColorModeValue("white", "gray.800");
	let searchIcon = useColorModeValue("gray.700", "gray.200");
	let mainTeal = useColorModeValue("orange.500", "orange.500");
	const [showMake, setShowMake] = useState("");

	const data = useMemo(
		() => accountsStore,
		[accountsStore],
	)

	const columnss = [
		/* {
			cell: (e) => <Text cursor={"pointer"} _hover={{color: listHoverColor, fontWeight: 600, textDecoration: "underline"}}>{e.shipping.first_name+" "+e.shipping.last_name}</Text>,
			name: 'Klant',
			selector: row => row.shipping.first_name+" "+row.shipping.last_name,
			sortable: true,
		}, */
		{
			name: 'App',
			cell: (e) => <>{(e.hasAccount&&<CheckIcon color={"green.300"} style={{
				height:'20px',
				width: '20px',
				marginRight: '2px'
			}}></CheckIcon>)||<CloseIcon color={"red.300"} style={{
				height:'20px',
				width: '20px',
				marginRight: '2px'
			}}></CloseIcon>}</>,
			selector: row => row.hasAccount,
			sortable: true,
			width: "100px"
		}, {
			cell: (e) => <Link to={"account/"+e.ID} cursor={"pointer"}><Text _hover={{color: listHoverColor, fontWeight: 600, textDecoration: "underline"}}>{e.Code.trim()}</Text></Link>,
			name: 'Code',
			selector: row => Number(row.Code.trim()),
			sortable: true,
			width: "100px"
		}, {
			cell: (e) => <Link to={"account/"+e.ID} cursor={"pointer"}><Text _hover={{color: listHoverColor, fontWeight: 600, textDecoration: "underline"}}>{e.Name}</Text></Link>,
			name: 'Account',
			selector: row => row.Name,
			sortable: true,
			/* width: "200px" */
		},
	];
	const onRowRemoving = (e) => {
        e.cancel = new Promise( async (resolve, reject) => {
            try {
                await deleteAppAccount(e.key);
                resolve();
            } catch (error) {
                reject(new Error("Could not contact server"));
            };
        });
    };
    const CellRender = e => {
        const rowdata = e.row.data;
        if (rowdata.hasAccount) {
            return (<>
                {<Link className="invenlink" to={"/account/"+rowdata.ID+"/account-info"}>{e.value}</Link>}
            </>)
        }
        return (<>
            {<Link className="invenlink" to="#" onClick={(e)=>{e.preventDefault(); console.log(rowdata.ID);setShowMake(rowdata)}}>{e.value}</Link>}
        </>)
    };
	return (
		<Box px={{sm: "2vw", md: "5vw", lg: "7vw"}} pt={4}>
			<Box>
				<DataGrid
					dataSource={data}
					keyExpr="ID"
					height={(height-(86))+"px"}
					hoverStateEnabled
					rowAlternationEnabled
					showBorders
					onRowRemoving={onRowRemoving}
					columnHidingEnabled
				>
					<Sorting mode="multiple" />
					<SearchPanel visible={true} />
					<Editing allowDeleting allowEditing useIcons/>
					<Column dataField="hasAccount" caption="App" width={40} allowFiltering={false} allowEditing={false}/>
					<Column dataField="Code" cellRender={CellRender}  caption="Code" width={80} allowEditing={false}/>
					<Column dataField="Name" cellRender={CellRender}  caption="Naam" sortOrder="asc" allowEditing={false}/>
					<Column dataField="Classification4" caption="Accountmanager" width={130} />
					<Column dataField="Classification1" caption="Vk - hoofd" width={100} />
					<Column dataField="Classification2" caption="Vk - sub 1" width={100} />
					<Column dataField="Classification3" caption="Vk - sub 2" width={100} />
					<Column dataField="Classification5" caption="Accountlevel" width={100} />
					<Column type="buttons" width={40} />
					{/* <Column dataField="Classification6" caption="Interne" width={100} /> */}
					{/* <Column dataField="Classification8" caption="Klantgroepen" width={100} /> */}
					<Paging enabled={true} defaultPageSize={50} />
					<Pager
						allowedPageSizes={[30, 50, 100, 200]}
						showInfo
						showNavigationButtons
						visible
						showPageSizeSelector
					/>
					{/* <Scrolling mode="virtual" /> */}
					<Selection mode="single" />
				</DataGrid>
			</Box>
		</Box>
	);
};
export default Page