import { mode } from "@chakra-ui/theme-tools";

export const globalStyles = {

  "colors": {
    "blue": {
      "50": "#EAF3FA",
      "100": "#C5DEF2",
      "200": "#9FC8EA",
      "300": "#7AB2E1",
      "400": "#549DD9",
      "500": "#2F87D0",
      "600": "#256CA7",
      "700": "#1C517D",
      "800": "#133653",
      "900": "#091B2A"
    },
    "red": {
      "50": "#FAECEA",
      "100": "#F2C8C5",
      "200": "#EAA59F",
      "300": "#E1827A",
      "400": "#D95F54",
      "500": "#D03C2F",
      "600": "#A73025",
      "700": "#7D241C",
      "800": "#531813",
      "900": "#2A0C09"
    },
    "yellow": {
      "50": "#FAF9EA",
      "100": "#F2EEC5",
      "200": "#EAE39F",
      "300": "#E1D87A",
      "400": "#D9CD54",
      "500": "#D0C22F",
      "600": "#A79B25",
      "700": "#7D741C",
      "800": "#534D13",
      "900": "#2A2709"
    },
    "green": {
      "50": "#b9c6be",
      "100": "#9dafa4",
      "200": "#81988a",
      "300": "#A0B2A6",
      "400": "#809888",
      "500": "#677f6f",
      "600": "#81988a",
      "700": "#677e70",
      "800": "#506257",
      "900": "#39463e"
    },
    "gray": {
      "50": "#f2f2f2",
      "100": "#d8d9d9",
      "200": "#bec0bf",
      "300": "#a5a7a6",
      "400": "#8b8e8c",
      "500": "#717473",
      "600": "#585a59",
      "700": "#505251",
      "750": "#393b3a",
      "800": "#222323",
      "900": "#0d0d0d"
    },
    "orange": {
      "50": "#FAF3EA",
      "100": "#F2DDC5",
      "200": "#EAC79F",
      "300": "#E1B17A",
      "400": "#D99B54",
      "500": "#D0852F",
      "600": "#A76B25",
      "700": "#7D501C",
      "800": "#533513",
      "900": "#2A1B09"
    },
    "teal": {
      "50": "#ECF9F7",
      "100": "#C9EDE8",
      "200": "#A7E2DA",
      "300": "#84D7CB",
      "400": "#62CBBC",
      "500": "#3FC0AE",
      "600": "#329A8B",
      "700": "#267368",
      "800": "#194D45",
      "900": "#0D2623"
    },
    "cyan": {
      "50": "#EAF7FA",
      "100": "#C5E9F2",
      "200": "#9FDBEA",
      "300": "#7ACDE1",
      "400": "#54BFD9",
      "500": "#2FB0D0",
      "600": "#258DA7",
      "700": "#1C6A7D",
      "800": "#134753",
      "900": "#09232A"
    },
    "purple": {
      "50": "#F1ECF8",
      "100": "#D6CAED",
      "200": "#BCA8E1",
      "300": "#A285D5",
      "400": "#8863CA",
      "500": "#6E41BE",
      "600": "#583498",
      "700": "#422772",
      "800": "#2C1A4C",
      "900": "#160D26"
    },
    "pink": {
      "50": "#F9ECF8",
      "100": "#EDCAED",
      "200": "#E1A8E1",
      "300": "#D585D5",
      "400": "#CA63C9",
      "500": "#BE41BD",
      "600": "#983497",
      "700": "#722771",
      "800": "#4C1A4C",
      "900": "#260D26"
    }
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("gray.50", "gray.800")(props),
        fontFamily: 'Helvetica, sans-serif'
      },
      html: {
        fontFamily: 'Helvetica, sans-serif'
      }
    }),
  },
};
