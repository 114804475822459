import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Box, Grid, Text, useColorModeValue, InputGroup, InputLeftElement, IconButton, Input } from '@chakra-ui/react';
import { CheckIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons';
import useWindowDimensions from "helpers/screensize";
import DataTable, { createTheme } from 'react-data-table-component';
import { useMainStore } from "data";
import { round, find, forEach } from "lodash";
import B2BAPI from "api";
import PriceList from "./components/priceList";

createTheme(
	'ddark',
	{
		text: {
			primary: "#fff",
			secondary: '#fff',
		},
		background: {
			default: '#2B323B',
		},
		context: {
			background: '#cb4b16',
			text: '#FFFFFF',
		},
		divider: {
			default: '',
		},
		button: {
			default: '#2aa198',
			hover: 'rgba(0,0,0,.08)',
			focus: 'rgba(255,255,255,.12)',
			disabled: 'rgba(255, 255, 255, .34)',
		},
		sortFocus: {
			default: '#2aa198',
		},
		striped: {
			default: 'rgba(255, 255, 255, .05)',
			text: 'rgba(255,255,255)',
		},
	}
);

createTheme(
	'llight',
	{
		text: {
			primary: "#000",
			secondary: '#000',
		},
		background: {
			default: '#F0F2F4',
		},
		context: {
			background: '#cb4b16',
			text: '#FFFFFF',
		},
		divider: {
			default: '',
		},
		button: {
			default: '#2aa198',
			hover: 'rgba(0,0,0,.08)',
			focus: 'rgba(255,255,255,.12)',
			disabled: 'rgba(255, 255, 255, .34)',
		},
		sortFocus: {
			default: '#2aa198',
		},
	},
);

const Page = (props) => {
	const { accountID, pageName } = useParams();
	console.log(pageName);
	const accountsStore = useMainStore(state => state.accounts)
	const { height } = useWindowDimensions();
	let inputBg = useColorModeValue("white", "gray.800");
	let searchIcon = useColorModeValue("gray.700", "gray.200");
	let mainTeal = useColorModeValue("orange.500", "orange.500");
	let tablecolor = useColorModeValue('llight', 'ddark');
	const [search, setSearch] = useState("");
	const [getPageLoading, setPageLoading] = useState("");
	const [getItemsData, setItemsData] = useState([]);
	const [getAccountData, setAccountData] = useState({});
	const [getUserItems, setUserItems] = useState([]);
	const [getAccountUsers, setAccountUsers] = useState([]);
	const [getAccountAddresses, setAccountAddresses] = useState([]);

    const calcNewPrice = (baseprice, discount) => {
        if (discount === 0) return baseprice;
        return round(baseprice-(baseprice*discount), 2)
    };

	const data = React.useMemo(async () => {
        let account = find(accountsStore, {ID: accountID})
		if (!account) return {};
		setPageLoading(true);
        const b2bMainGet = await B2BAPI.get(`/d/b2bCustomerDatasV2?overId=b2bmain&fields=-userItems.__metadata,-userItems.Division,-userItems.EntryMethod,-userItems.PriceListCode,-userItems.PriceListDescription,-userItems.PriceListPeriod,-userItems.cover,-userItems.coverDescription`);
        const items = await B2BAPI.get(`/d/Items?IsSalesItem=true&fields=SalesVatCode,ID,ItemGroupCode,ItemGroupDescription`);
        const cusAcountData = await B2BAPI.get(`/d/b2bCustomerDatasV2?customerId=${accountID}`);
		//const cusUsers = await B2BAPI.get(`/auth?from=${props.getSelectionData}`);

        const fixxex = [];
        const b2bmain = b2bMainGet.data[0];

        forEach(b2bmain.userItems, eze=>{
            let ezel = eze;
            let fgh = find(items.data, {"ID": eze.Item});
            if (!fgh) return;
            fixxex.push({...ezel, ItemGroupDescription:fgh.ItemGroupDescription, ItemGroupCode:fgh.ItemGroupCode, SalesVatCode: fgh.SalesVatCode?fgh.SalesVatCode.trim():"1"})
        });

		setItemsData(fixxex);

		const dataaa = [];
        forEach(cusAcountData.data[0].userItems, e=>{
            let itfi = find(fixxex, {"Item": e.Item})
            if (itfi) {
                e.NewPrice = calcNewPrice(itfi.BasePriceAmount, e.Discount)
                itfi = {...itfi, ...e}
                dataaa.push(itfi);
            };
        })
        console.log(dataaa);
        setAccountData({...cusAcountData.data[0], userItems: dataaa});
        setUserItems(dataaa)

		const loadUsers = async () => {
			const users = await B2BAPI.get(`/auth?from=${accountID}`);
			setAccountUsers(users.data);
		};

		const loadAddresses = async () => {
			const gotAddresses = await B2BAPI.get(`/d/b2bCustomerDatasV2?customerId=${accountID}&fields=addresses`);
			setAccountAddresses(gotAddresses.data[0].addresses);
		};

        await loadUsers()
        await loadAddresses()
        setPageLoading(false);
		return account;
    }, [accountID, accountsStore]);

	const columnss = [
		/* {
			cell: (e) => <Text cursor={"pointer"} _hover={{color: listHoverColor, fontWeight: 600, textDecoration: "underline"}}>{e.shipping.first_name+" "+e.shipping.last_name}</Text>,
			name: 'Klant',
			selector: row => row.shipping.first_name+" "+row.shipping.last_name,
			sortable: true,
		}, */
		{
			name: 'App',
			cell: (e) => <>{(e.hasAccount&&<CheckIcon color={"green.300"} style={{
				height:'20px',
				width: '20px',
				marginRight: '2px'
			}}></CheckIcon>)||<CloseIcon color={"red.300"} style={{
				height:'20px',
				width: '20px',
				marginRight: '2px'
			}}></CloseIcon>}</>,
			selector: row => row.hasAccount,
			sortable: true,
			width: "100px"
		}, {
			name: 'Code',
			selector: row => Number(row.Code.trim()),
			sortable: true,
			width: "100px"
		}, {
			name: 'Account',
			selector: row => row.Name,
			sortable: true,
			/* width: "200px" */
		},
	];

	if (!accountID) return (
		<Redirect to="/accounts" />
	);

	return (
		<PriceList getUserItemsData={getUserItems} getSelectionData={data} getItemsData={getItemsData} getAccountData={getAccountData} reloadAccountItems={()=>{}} setStateUpdate={()=>{}}/>
	);

	/* return (
		<Box px={{sm: "2vw", md: "5vw", lg: "7vw"}} pt={4}>
			<Grid gap={"40px"} w="100%" templateColumns={{ sm: "1fr", md: "1fr 4fr", lg: "1fr 4fr" }} templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}>
				<Box>
					<InputGroup
						cursor="pointer"
						bg={inputBg}
						borderRadius="15px"
						me={{ sm: "auto", md: "20px" }}
						mt={5}
						_focus={{
							borderColor: { mainTeal },
						}}
						_active={{
							borderColor: { mainTeal },
						}}
					>
						<InputLeftElement
							children={
								<IconButton
									bg="inherit"
									borderRadius="inherit"
									_active={{
										bg: "inherit",
										transform: "none",
										borderColor: "transparent",
									}}
									_focus={{
										boxShadow: "none",
									}}
									icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
								></IconButton>
							}
						/>
						<Input
							fontSize="xs"
							py="11px"
							color={"white"}
							value={search}
							onChange={e=>{console.log(e.target.value);setSearch(e.target.value)}}
							placeholder="Zoeken..."
							borderRadius="inherit"
						/>
					</InputGroup>
				</Box>
				<Box>
					<DataTable
						columns={columnss}
						data={data}
						theme={tablecolor}
						fixedHeader
						fixedHeaderScrollHeight={(height-(86))+"px"}
						striped
					/>
				</Box>
			</Grid>
		</Box>
	); */
};
export default Page