import B2BAPI from "api";
import { isEmpty, find } from "lodash";
//import toast, { toastOptions_L } from "components/Toaster";

export const getCoffees = async (from) => {
    const res2 = await B2BAPI.get(`/d/btCoffees`);
    //const res = await B2BAPI.get(`/Items?IsSalesItem=true&fields=SalesVatCode,ID,ItemGroupCode`);
    //const res = await B2BAPI.get(`/b2bCatagories?status=true&sort=sortNr`);
    //const res = await B2BAPI.get(`/b2bCustomerDatasV2?overId=b2bmain&fields=-userItems.__metadata`);
    return res2.data
}