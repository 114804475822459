import BEANAPI from './beanApi';
import { curdiv } from "./config";

export const __GoodsDeliveries = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/salesorder/GoodsDeliveries`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		default:
			break;
	}
};

export const __GoodsDeliveryLines = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/salesorder/GoodsDeliveryLines`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		default:
			break;
	}
};

export const __PlannedSalesReturnLines = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/salesorder/PlannedSalesReturnLines`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};

export const __PlannedSalesReturns = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/salesorder/PlannedSalesReturns`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};
//${curdiv}
export const __PrintedSalesOrders = (data) => {
	const baseUri = `/exact/post/2946080/salesorder/PrintedSalesOrders`;
	return BEANAPI.post(baseUri, data);
};

export const __SalesOrderLines = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/salesorder/SalesOrderLines`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};

export const __SalesOrders = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/salesorder/SalesOrders`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};
