import { BEANAPI } from './index';

export const wProductsC = (t, e, d) => {
    switch (t) {
        case 'get':
            return BEANAPI.get('/woocommerce/mainC/products'+e);
        case 'post':
            return BEANAPI.post('/woocommerce/mainC/products'+e, d);
        case 'put':
            return BEANAPI.put('/woocommerce/main/products'+e, d);
        case 'delete':
            return BEANAPI.delete('/woocommerce/mainC/products'+e);
        case 'options':
            return BEANAPI.options('/woocommerce/mainC/products'+e);
        default:
            break;
    }
};

export const wProducts = (t, e, d) => {
    switch (t) {
        case 'get':
            return BEANAPI.get('/woocommerce/main/products'+e);
        case 'post':
            return BEANAPI.post('/woocommerce/main/products'+e, d);
        case 'put':
            return BEANAPI.put('/woocommerce/main/products'+e, d);
        case 'delete':
            return BEANAPI.delete('/woocommerce/main/products'+e);
        case 'options':
            return BEANAPI.options('/woocommerce/main/products'+e);
        default:
            break;
    }
};

export const wOrders = (t, e, d) => {
    switch (t) {
        case 'get':
            return BEANAPI.get('/woocommerce/main/orders'+e);
        case 'post':
            return BEANAPI.post('/woocommerce/main/orders'+e, d);
        case 'put':
            return BEANAPI.put('/woocommerce/main/orders'+e, d);
        case 'delete':
            return BEANAPI.delete('/woocommerce/main/orders'+e);
        case 'options':
            return BEANAPI.options('/woocommerce/main/orders'+e);
        default:
            break;
    }
};

export const wShipTrackO = (t, e, d) => {
    switch (t) {
        case 'get':
            return BEANAPI.get('/woocommerce/shipment/orders'+e);
        case 'post':
            return BEANAPI.post('/woocommerce/shipment/orders'+e, d);
        case 'put':
            return BEANAPI.put('/woocommerce/shipment/orders'+e, d);
        case 'delete':
            return BEANAPI.delete('/woocommerce/shipment/orders'+e);
        case 'options':
            return BEANAPI.options('/woocommerce/shipment/orders'+e);
        default:
            break;
    }
};