import create from 'zustand'
import * as _items from "./helpers/items";
import * as _accounts from "./helpers/accounts";
import * as _coffees from "./helpers/coffees";
import B2BAPI from "api";
import { wOrders } from "api/woocommerce";
import { find, forEach, filter, every, toArray, sortBy, sumBy, isEmpty } from "lodash";
import toast from 'react-hot-toast';
let intervalWB = null;
let intervalWS = null;
export const useMainStore = create( (set, get) => ({

// coffees store START
    btCoffees: [],
    initCoffees: async () => {
        try {
            const response = await _coffees.getCoffees();
            console.log(response);
            set({ btCoffees: response });
            return response;
        } catch (error) {
            set({ btCoffees: [] });
        }
    },
// coffees store END

// items store START
    items: [],
    btProducts: [],
    initItems: async () => {
        try {
            const response = await _items.getItems();
            console.log(response);
            set({ items: response.items, btProducts: response.btProducts });
            return response;
        } catch (error) {
            set({ items: [], btProducts: [] });
        }
    },
    updateItem: async (ID, data) => {
        toast.info('Item update gestart');
        try {
            await _items.updateItem(ID, data);
            await get().initItems();
            toast.success('Item update uitgevoerd');
        } catch (error) {
            toast.error('Kon data niet opslaan.');
            console.log(error);
            set({ items: [] });
        }
    },
    upsertItem: async (ID, data) => {
        toast.info('Item update gestart');
        try {
            await _items.upsertItem(ID, data);
            await get().initItems();
            toast.success('Item update uitgevoerd');
        } catch (error) {
            toast.error('Kon data niet opslaan.');
            console.log(error);
            set({ items: [] });
        }
    },
    updateFromExact: async () => {
        try {
            await _items.getItemsFromExact();
            await get().initItems();
        } catch (error) {
            set({ items: [] });
        }
    },
    itemsInventory: async () => {
        try {
            const response = await _items.getMaterialsInventory();
            console.log(response);
            return get().items.map( e => {
                const inventory = find(response, {"item": e.ID});
                return { ...e, inventory, Stock: inventory&&inventory.stock }
            } )
        } catch (error) {
            return ({ items: [] });
        }
    },
    removeItem: _items.removeItem,
// items store END

// accounts store START
    accounts: [],
    initAccounts: async () => {
        try {
            const response = await _accounts.getAccounts();
            set({ accounts: response });
            console.log(response);
            return response;
        } catch (error) {
            set({ accounts: [] });
        }
    },
    deleteAppAccount: async (ID) => {
        await _accounts.deleteAppAccount(ID);
        await get().initAccounts();
    },
    updateAccountsFromExact: async () => {
        try {
            await _accounts.getAccountsFromExact();
            await get().initAccounts();
        } catch (error) {
            set({ accounts: [] });
        }
    },
// accounts store END

// ordersOpen store START
    ordersOpen: [],
    initOrders: () => {

    },
// ordersOpen store END

// newOrders store START
    webShopOrders: [],
    webShopOrdersAmount: 0,
    webShopOrdersGet: () => {
        if (intervalWB) clearInterval(intervalWB);
        const getter = async () => {
            try {
                const result = await wOrders('get', `?per_page=100&status=processing`)
                let datat = [];
                let dataRos = [];
                if (!isEmpty(result.data)) {

                }
                console.log(datat);
                set({ webShopOrders: datat, webShopOrdersAmount: datat.length });
            } catch (error) {
                toast.info('Kon webshop orders niet ophalen, herlaad de pagina om opnieuw te proberen');
            }
        }
        getter()
        intervalWB = setInterval( getter, 300000);
    },
    wholesaleOrders: [],
    wholesaleOrdersAmount: 0,
    wholesaleOrdersGet: () => {
        if (intervalWS) clearInterval(intervalWS);
        const getter = async() => {
            try {
                const epost = await B2BAPI.get(`/d/b2bOrders?status=0`)
                const itms = get().items.map(e => { const {Description,ID,Code,ItemGroupCode,Status,SalesVatCode} = e; return {Description,ID,Code,ItemGroupCode,Status,SalesVatCode} })
                const ords = [];

                console.log(ords);
                set({wholesaleOrders: ords, wholesaleOrdersAmount: ords.length });
            } catch (error) {
                toast.info('Kon b2b-app orders niet ophalen, herlaad de pagina om opnieuw te proberen');
            }
        }
        getter()
        intervalWS = setInterval( getter, 300000);
    },
// newOrders store END

// productionSchedules store START
    productionSchedulesLoring: [],
    productionSchedulesProbat: [],
    productionSchedulesLoringDone: [],
    productionSchedulesProbatDone: [],
    productionSchedulesCountLoring: 0,
    productionSchedulesCountProbat: 0,
    productionSchedulesCountCropLoring: 0,
    productionSchedulesCountCropProbat: 0,

    productionSchedulesGreenWeight: 0,
    productionSchedulesCount: 0,

    initProductionSchedules: async () => {

    }
// productionSchedules store END

}))