import {
	Box, Grid, SimpleGrid, Text, useColorModeValue, Badge, Flex, Spacer, Button, Modal, ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,FormControl,FormLabel,Input,ModalFooter,useDisclosure,HStack,useNumberInput, Heading, Divider
} from '@chakra-ui/react';
import { useAuthUser } from 'react-auth-kit';
import React, { useState, useEffect } from 'react';
import { useMainStore } from "data";
import { find, forEach, isEmpty } from "lodash";
import B2BAPI from "api";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js"


function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function Home({ getTraining, setTraining }) {
	const auth = useAuthUser();
	const user = auth();
    const [getPageLoading, setPageLoading] = useState(true);
    const [getOpenData, setOpenData] = useState({});
    const [getPlannedTrainings, setPlannedTrainings] = useState([]);
    const [getTrainingItems, setTrainingItems] = useState([]);
	const t_listItem_bg = useColorModeValue("white", "gray.700");
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [productionSchedulesGreenWeight, productionSchedulesCount] = useMainStore(state => [state.productionSchedulesGreenWeight, state.productionSchedulesCount]);

	const loadUsers = async () => {
        setPageLoading(true);
        const b2bTrainings = await B2BAPI.get(`/d/b2bTrainings?done!=true&trainingCode=${getTraining.ItemCode}&sort=startDate`);

        setPlannedTrainings(b2bTrainings.data);

        await timeout(500);
        setPageLoading(false);
        console.log(b2bTrainings);
    };

	useEffect(() => {
		if (isEmpty(getTraining)) return;
		loadUsers();
	}, []);

	return (
		<Grid h="fit-content" maxW="100%" m="0 auto" px={{sm: "2vw", md: "5vw", lg: "7vw"}} py={4}>
			<Flex>
				<Box pb='4'>
					<HStack>
						<Button onClick={e=>{setTraining()}}>Terug</Button>
						<Heading fontSize={"1.4rem"} noOfLines={1}>
							{getTraining.ItemDescription}
						</Heading>
					</HStack>

				</Box>
				<Spacer />
				<Box pb='4'>
					<Button onClick={()=>{setOpenData({
						standardPlaces: 1
					});onOpen()}}>Toevoegen</Button>
				</Box>
			</Flex>
			<SimpleGrid columns={{md: 1}} spacing='20px'>
				{getPlannedTrainings.map( (e, i) =>
					<Box key={i} borderRadius={"lg"} bgColor={t_listItem_bg} scale w="100%" py={5} px={6} _hover={{ filter: "brightness(90%)", cursor: "pointer", bg: t_listItem_bg }} onClick={()=>{setOpenData(e);onOpen()}}>
						<h6>{moment(e.startDate).format("dddd, DD MMM YYYY")} ({e.startTime} - {e.endTime})</h6>
						<span className="ms-4">{e.text}</span><br />
						<span className="ms-4">Aantal reserveringen: {e.soldPlaces||0} <small>v/d</small> {e.standardPlaces} <small>plaatsen</small> {(e.soldPlaces||0)>=e.standardPlaces&&<Badge bg='danger'>VOL</Badge>}</span><br />
						{/* {(e.bookStatus===false&&<Badge className="ms-4" bg='danger'><small>RESERVERINGEN GESLOTEN</small></Badge>)||<Badge className="ms-4" bg='success'><small>RESERVERINGEN OPEN</small></Badge>} */}
						{/* <Text fontSize={"1.4rem"}>{e.ItemDescription}</Text> */}
					</Box>
				)}
			</SimpleGrid>
			{!isEmpty(getOpenData)&&<EditModal data={{isOpen, onOpen, onClose,  getOpenData, loadUsers, getTraining}} />}
		</Grid>
	);
};

export default Home;

function EditModal({data}) {
	const { isOpen, onOpen, onClose, getOpenData, loadUsers, getTraining } = data;
    const [isOpenC, setIsOpenC] = useState(false);

	const initialRef = React.useRef(null)
	const onSubmit =  async x =>{
		x.preventDefault();
		//console.log(x.target[1].value.split(','));

		const dataSaver = {
			...getOpenData,
			date: moment(x.target[1].value).toISOString(true),
			startDate: moment(x.target[1].value).toISOString(true),
			plannedDate: moment(x.target[1].value).toISOString(),
			trainLocation: x.target[3].value,
			startTime: x.target[4].value,
			endTime: x.target[5].value,
			standardPlaces: Number(x.target[7].value),
			bookStatus: true
		}

		if (getOpenData.aid) await B2BAPI.post('/d/b2bTrainings', [{
			"updateOne" : {
				"filter": {"aid": getOpenData.aid},
				"update" : dataSaver
			}
		}]);

		if (!getOpenData.aid) {
			const setter = x.target[1].value.split(',').map(x=>{ return {
                "insertOne" : {
                    "document": {
                        ...dataSaver,
                        aid: uuidv4(),
						text: getTraining.ItemDescription,
						trainingCode: getTraining.ItemCode,
                        date: moment(x.trim()).toISOString(true),
                        startDate: moment(x.trim()).toISOString(true),
						plannedDate: moment(x.trim()).toISOString(),
						soldPlaces: 0,
                    }
                }
            } });
			//console.log(setter);
			await B2BAPI.post('/d/b2bTrainings', setter)
		};

		loadUsers();
		onClose();
		//console.log(dataSaver);
	};
	const confirmArc =  async x =>{
		x.preventDefault();
		await B2BAPI.post('/d/b2bTrainings', [{
            "updateOne" : {
                "filter": {"aid": getOpenData.aid},
                "update" : {done: true}
            }
        }])

		loadUsers();
		onClose();
		onCloseC()
		//console.log(dataSaver);
	};
	const onCloseC = x =>{
		setIsOpenC(false)
	};
	return (
	  <>
		<Modal
		  initialFocusRef={initialRef}
		  isOpen={isOpen}
		  onClose={onClose}
		  isCentered
		>
			<ModalOverlay backdropFilter='blur(10px)' />
			<ModalContent><form onSubmit={onSubmit}>
				<ModalHeader>{getOpenData.text||getTraining.ItemDescription}</ModalHeader>
				<ModalCloseButton />

				<Divider mb={4} mt={0} />
				<ModalBody pb={6}>
					<FormControl pb={3}>
						<FormLabel>Datum {!getOpenData.aid&&<small>{"(meerdere mogelijk)"}</small>}</FormLabel>
						<Flatpickr
							className='flatData'
							render={
							({defaultValue, value, ...props}, ref) => {
								return <CustomInput defaultValue={defaultValue} inputRef={ref} />
							}
							}
							options={{
								allowInput: true,
								mode: !getOpenData.aid?"multiple":"single",
								defaultDate: !getOpenData.aid?moment():moment(getOpenData.startDate).format("YYYY-MM-DD"),
								minDate: moment(),
								altInput: true,
								altFormat: "j F Y",
								dateFormat: "Y-m-d",
								"locale": Dutch,
								onChange: (selectedDates, dateStr, instance) => {
									console.log(moment(selectedDates[0]).toISOString(true));
								},
							}}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>Locatie</FormLabel>
						<Input ref={initialRef} placeholder='Locatie' defaultValue={getOpenData.trainLocation||""} required />
					</FormControl>

					<FormControl mt={4}>
						<FormLabel>Start tijd</FormLabel>
						<Input type="time" defaultValue={getOpenData.startTime||""} placeholder='00:00' required/>
					</FormControl>

					<FormControl mt={4}>
						<FormLabel>Eind tijd</FormLabel>
						<Input type="time" defaultValue={getOpenData.endTime||""} placeholder='00:00' required/>
					</FormControl>

					<FormControl mt={4}>
						<FormLabel>Plaatsen</FormLabel>
						<HookUsage standardPlaces={getOpenData.standardPlaces||1}/>
					</FormControl>
				</ModalBody>
					<Divider mt={4} />

				<ModalFooter>
					<Button onClick={onClose} mr={3}>Sluit</Button>
					{getOpenData.aid&&<Button onClick={()=>{setIsOpenC(true)}} mr={3} colorScheme='orange'>Archiveren</Button>}
					<Button colorScheme='blue' type="submit">
						{(!getOpenData.aid&&"Toevoegen")||"Opslaan"}
					</Button>
				</ModalFooter>
		  	</form></ModalContent>
		</Modal>
		<ConfirmModal data={{isOpenC, onCloseC, confirmArc}} />
	  </>
	)
}

function ConfirmModal({data}) {
	const { isOpenC, onCloseC, confirmArc } = data

	return (
	  <>
		<Modal
		  isOpen={isOpenC}
		  onClose={onCloseC}
		  isCentered
		>
			<ModalOverlay backdropFilter='blur(10px)' />
			<ModalContent><form onSubmit={confirmArc}>
				<ModalHeader>Archiveren?</ModalHeader>
				<ModalCloseButton />

				<Divider mb={4} mt={0} />
				<ModalBody pb={6}>
					<p>Wil je deze workshop archiveren?</p>
				</ModalBody>
				<Divider mt={4} />

				<ModalFooter>
					<Button onClick={onCloseC} mr={3}>Annuleren</Button>
					<Button colorScheme='orange' type="submit">
						Archiveren
					</Button>
				</ModalFooter>
		  	</form></ModalContent>
		</Modal>
	  </>
	)
}

function HookUsage({standardPlaces}) {
	const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
	  useNumberInput({
		step: 1,
		defaultValue: standardPlaces,
		min: 0,
		precision: 0,
	  })

	const inc = getIncrementButtonProps()
	const dec = getDecrementButtonProps()
	const input = getInputProps()

	return (
	  <HStack>
		<Button {...dec}>-</Button>
		<Input {...input} />
		<Button {...inc}>+</Button>
	  </HStack>
	)
}

const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
    return <Input {...props} defaultValue={defaultValue} ref={inputRef} required />;
};