import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import {
	Box, Grid, SimpleGrid, Text, useColorModeValue, Badge, Flex, Spacer, Button, useToast, Heading
} from '@chakra-ui/react';
import _ from "lodash";
import { __SalesOrders, __GoodsDeliveries, __PrintedSalesOrders } from 'api/_exact/salesOrder';
import { __InvoiceSalesOrders, __SalesInvoices, __PrintedSalesInvoices } from 'api/_exact/salesInvoice';
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import useWindowDimensions from "helpers/screensize";
import DataGrid, {
    Column,
    Paging,
    SearchPanel,
    Selection,
    Sorting,
    Pager, Editing
} from 'devextreme-react/data-grid';

import EcommerceOrdersModal from "./EcommerceOrdersModal";

//import { confirm } from 'devextreme/ui/dialog';
import B2BAPI from "api";

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const EcommerceOrders = props => {
	const { height } = useWindowDimensions();
	const toast = useToast();
	const toastIdRef = React.useRef();
	const [getBooked, setBooked] = useState([]);
    const [getPageLoading, setPageLoading] = useState(true);

    const loadBooked = async () => {
        setPageLoading(true);
        const epost = await B2BAPI.get(`/d/b2bBookedTrainings?sort=-orderDate&limit=200`)//&status=420,421
        setBooked(epost.data);
		console.log(epost.data);
        await timeout(500)
        setPageLoading(false);
    };

	useEffect(() => {
		loadBooked()
    }, []);

	const [modal, setModal] = useState(false);
	const [modal1, setModal1] = useState(false);
	const [modalData, setModalData] = useState({});
	const [getLoadIndicatorVisible, setLoadIndicatorVisible] = useState(false);

	//pagination customization
	const pageOptions = {
		sizePerPage: 20,
		totalSize: getBooked.length, // replace later with size(orders),
		custom: true,
	};

	// const toggleModal = () => {
	//   setModal1(!modal1)
	// }
	const toggleViewModal = () => setModal1(!modal1);

	const statusRet = (e) => {
		switch (e) {
			case 420:
				return <Badge className={"font-size-12"} pill bg="warning">Nieuw</Badge>;
			case 410:
				return <Badge className={"font-size-12"} pill bg="info">Ingepland</Badge>;
			case 421:
				return <Badge className={"font-size-12"} pill bg="success">Gefactureerd</Badge>;
			case 400:
				return <Badge className={"font-size-12"} pill bg="danger">Geannuleerd</Badge>;
			default:
				break;
		}
	};

	const EcommerceOrderColumns = toggleModal => [
		/* {
			dataField: "OrderNumber",
			text: "Order nummer",
			sort: true,
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => (
				<Link to="#" className="text-body fw-bold">
					{row.OrderNumber}
				</Link>
			),
		}, */
		{
			dataField: "contactName",
			text: "Klant",
			sort: true,
		}, {
			dataField: "orderDate",
			text: "Datum",
			sort: true,
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => handleValidDate(row.orderDate),
		}, {
			dataField: "Status",
			text: "Status",
			sort: true,
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => statusRet(row.status),
		}, {
			dataField: "view",
			isDummyField: true,
			text: "View Details",
			sort: true,
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => (
				<Button
					type="button"
					color="outline-secondary"
					className="btn-sm btn-rounded"
					onClick={(rowo)=>{toggleViewModal(); setModalData(row)}}
				>
					Details bekijken
				</Button>
			),
		}
	];

	const toggle = () => {
		if (modal) {
			setModal(false);
		} else {
			setModal(true);
		}
	};

	var node = useRef();

	const handleValidDate = date => {
		const date1 = moment(new Date(date)).format("DD MMM Y");
		return date1;
	};

	const defaultSorted = [
		{
			dataField: "OrderNumber",
			order: "desc",
		},
	];

	const submitOrder = async (x) => {
		const orderData = x.order;
		setLoadIndicatorVisible(true);
        try {
			const configer = orderData.SalesOrderLines.map(e=>{ return {ID: e.ID, OrderID: e.OrderID, Item: e.Item, Quantity: e.Quantity, NetPrice: e.NetPrice}})
			const salesOrder = {...orderData, DeliveryDate: moment().format("YYYY-MM-DD"), WarehouseID: "8c2ac062-6574-4427-9467-1ca49745cd91", SalesOrderLines: configer};
            const ept = await __SalesOrders('post', '', salesOrder);
            const orderRet = {...ept.data.d};
			const entryID = uuidv4();
			toastIdRef.current = toast({
				title: 'Order aangemaakt.',
				description: "Nog even geduld aub.",
				status: 'success',
				duration: 100000,
				isClosable: false,
			});
			B2BAPI.post(`/d/b2bBookedTrainings`, {data: {status: 423}, key: x.OrderID, keyType: "OrderID"})
            const mDl = {
                "EntryID": entryID,
                "DeliveryDate": moment().format("YYYY-MM-DD"),
                "GoodsDeliveryLines": configer.map(e=> {return {
                    "EntryID": entryID,
                    "QuantityDelivered" : e.Quantity,
                    "SalesOrderLineID" : e.ID,
                }})
            };

            const eeer = await __GoodsDeliveries('post', '', mDl);
			toast.update(toastIdRef.current, { title: 'Order word gefactureerd.', description: 'Nog even geduld aub.' });

			const mDl2 = {
				"CreateMode" : "1",
				"InvoiceMode" : "1",
				"JournalCode" : "1",
				"SalesOrderIDs": [{
					"ID" : x.OrderID
				}]
			};

			await __InvoiceSalesOrders('post', '', mDl2);

			const eee = await __SalesInvoices('get', `?$filter=OrderNumber eq ${orderRet.OrderNumber}&$select=InvoiceID`);

			const mDl3 = {
				"InvoiceID" : eee.data.d.results[0].InvoiceID,
				"SendEmailToCustomer" : true,
				"SenderEmailAddress": "facturatie@bocca.nl",
				"DocumentLayout": "55b67dcc-10af-4a3a-8635-b5f4088de3d4"
			};

			await __PrintedSalesInvoices('post', '', mDl3);

			B2BAPI.post(`/d/b2bBookedTrainings`, {data: {status: 421}, key: x.OrderID, keyType: "OrderID"})
			toast.update(toastIdRef.current, { title: 'Factuur aangemaakt.', description: '', duration: 5000, isClosable: true });
			setLoadIndicatorVisible(false);
			loadBooked();
			toggleViewModal()
        } catch (error) {
			setLoadIndicatorVisible(false);
			toast.update(toastIdRef.current, { title: 'Er is iets misgegaan', description: '', duration: 9000, isClosable: true, status: 'error', });
            console.error(error);
        };
	};

	/* const conPop = (txt, btn_txt, runer, runnerdata) => {
		let result = confirm("<i>"+txt+"</i>", btn_txt);
        result.then((dialogResult) => {
			if (dialogResult) {
				runer(runnerdata)
			}
        });
	}; */

	const removeOrder = async (x) => {
		console.log(x);
		setLoadIndicatorVisible(true);
        try {
			///const n2Map = [{
			///	"updateOne" : {
			///		"filter": {"aid": x.data.selectedDate.aid},
			///		"update" : {$inc:{ soldPlaces: Number("-"+x.data.deelnemers.length)}}
			///	}
			///}];
			///await B2BAPI.post(`/d/b2bTrainings`, n2Map)
			await B2BAPI.post(`/d/b2bBookedTrainings`, {data: {status: 400}, key: x.data.OrderID, keyType: "OrderID"})

			toast({
				title: 'Reservering geannuleerd',
				description: "",
				status: 'info',
				duration: 6000,
				isClosable: true,
			});
			//console.log(n2Map);
			await timeout(500)
			loadBooked();
			setModal1(false);
			setModalData({});
			setLoadIndicatorVisible(false);
        } catch (error) {
			setLoadIndicatorVisible(false);
			toast({
				title: 'Er is iets misgegaan',
				description: "",
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
            console.error(error);
        };
	};
	const confirmOrder = async (x) => {
		setLoadIndicatorVisible(true);
        try {
			B2BAPI.post(`/d/b2bBookedTrainings`, {data: {status: 410}, key: x.OrderID, keyType: "OrderID"})
			toast({
				title: 'Reservering verplaatst naar ingepland',
				description: "",
				status: 'info',
				duration: 5000,
				isClosable: true,
			});
			await timeout(500)
			loadBooked();
			setModal1(false);
			setModalData({});
			setLoadIndicatorVisible(false);
        } catch (error) {
			setLoadIndicatorVisible(false);
			toast({
				title: 'Er is iets misgegaan',
				description: "",
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
            console.error(error);
        };
	};
	const CellRender = e => {
        const rowdata = e.row.data;
		switch (rowdata.status) {
			case 420:
				return <Badge variant='subtle' colorScheme='orange'>Nieuw</Badge>;
			case 410:
				return <Badge variant='subtle' colorScheme='blue'>Ingepland</Badge>;
			case 421:
				return <Badge variant='subtle' colorScheme='green'>Gefactureerd</Badge>;
			case 400:
				return <Badge variant='subtle' colorScheme='red'>Geannuleerd</Badge>;
			default:
				break;
		}
    };
	return (
		<Box px={{sm: "2vw", md: "5vw", lg: "7vw"}} pt={4}>
			{!_.isEmpty(modalData)&&<EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} modalData={modalData} removeOrder={removeOrder} confirmOrder={confirmOrder} submitOrder={submitOrder} getLoadIndicatorVisible={getLoadIndicatorVisible} />}
			<Box>
				<DataGrid
					dataSource={getBooked}
					keyExpr="OrderID"
					height={(height-(86))+"px"}
					hoverStateEnabled
					rowAlternationEnabled
					showBorders
					onRowRemoving={removeOrder}
					columnHidingEnabled
					onRowClick={e=>{setModalData(e.data);setModal1(true)}}
				>
					<Sorting mode="multiple" />
					<SearchPanel visible={true} />
					<Editing allowDeleting allowEditing useIcons/>
					<Column dataField="contactName"  caption="Klant" allowEditing={false}/>
					<Column dataField="orderDate" caption="Datum" dataType={"date"} width={130} sortOrder="desc" />
					<Column dataField="status" cellRender={CellRender} caption="Status" alignment={"left"} width={100} />
					<Column /* type="buttons" */ width={40} /* ={x=>{console.log(x);}} */ >
						<i className="fad fa-trash"/>
					</Column>
					{/* <Column dataField="Classification6" caption="Interne" width={100} /> */}
					{/* <Column dataField="Classification8" caption="Klantgroepen" width={100} /> */}
					<Paging enabled={true} defaultPageSize={50} />
					<Pager
						allowedPageSizes={[30, 50, 100, 200]}
						showInfo
						showNavigationButtons
						visible
						showPageSizeSelector
					/>
					{/* <Scrolling mode="virtual" /> */}
					<Selection mode="single" />
				</DataGrid>
			</Box>
		</Box>
	);
};

export default withRouter(EcommerceOrders);

