import DataGrid, {
    Column,
    Editing,
    Grouping,
    GroupPanel, Pager, Paging, Scrolling,
    SearchPanel, Selection
} from 'devextreme-react/data-grid';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import _ from "lodash";
import React from 'react';
import useWindowDimensions from "helpers/screensize";
import B2BAPI from "api";

const calcNewPrice = (baseprice, discount) => {
    if (discount === 0) return baseprice;
    return _.round(baseprice-(baseprice*discount), 2)
};

const AccountSelected = (props) => {
    const { height, width } = useWindowDimensions();
    const refDataGrid = React.useRef(null);
    const ref = React.useRef(null);
    const [getDiscountPopup, setDiscountPopup] = React.useState(false);
    const [getDiscountPopupCat, setDiscountPopupCat] = React.useState(false);
    const [getLocItems, setLocItems] = React.useState([]);
    const [getGroups, setGroups] = React.useState([]);
    const [getState, setState] = React.useState({
        prefix: "",
        selectedRowsData: [],
        selectedRowKeys: []
    });
    const [getProductsNotSet, setProductsNotSet] = React.useState([]);
    React.useEffect( () => {
        var valueArr = props.getUserItemsData.map(function(item){ return item.Item });
        var isDuplicate = valueArr.some(function(item, idx){
            return valueArr.indexOf(item) !== idx
        });
        if (isDuplicate) {alert("bevat dubs")}
		setLocItems(props.getUserItemsData.map(o=>{return {...o, Discount: _.round(o.Discount*100, 2)}}))
    }, [props.getUserItemsData]);

    const removeFromPriceList = async () => {
        try {
            await B2BAPI.post(`/d/b2bCustomerDatasV2`, [{ updateOne :
                {
                "filter": {customerId: props.getAccountData.customerId},
                "update": { $pull: { "userItems": { "Item": { $in: getState.selectedRowKeys } } } }
                }
            }])
            props.setStateUpdate();
            ref.current.selectedRowKeys = [];
            setState({
                prefix: null,
                selectedRowsData: [],
                selectedRowKeys: []
            });
            props.reloadAccountItems();
        } catch (error) {
            console.error(error);
        }
    };
    const NewPriceCell = (data) => {
        const toFind = data.data.data;
        return (
            <>€ {toFind.NewPrice}</>
        )
    };
    const BasePriceAmountCell = (data) => {
        const toFind = data.data.data;
        return (
            <>€ {toFind.BasePriceAmount}</>
        )
    };
    const NewPriceCell2 = (data) => {
        const toFind = data.data.data;
        return (
            <div>{toFind.Discount?<div>{calcNewPrice(Number(toFind.BasePriceAmount), Number(_.round(toFind.Discount/100, 4)))}</div>:<div style={{backgroundColor:"#FF5050"}}>-</div>}</div>
        )
    };
    const DiscountCell2 = (data) => {
        const toFind = data.data.data;
        return (
            <div>{toFind.Discount?<div>{toFind.Discount}%</div>:<div>0%</div>}</div>
        )
    };
    const DiscountCell = (data) => {
        const toFind = data.data.data;
        return (
            <div style={{textDecoration:"underline dotted"}}>{toFind.Discount?<div>{toFind.Discount}%</div>:<div>0%</div>}</div>
        )
    };

    const discountPerCat = () => {
        const dataaas = [];
        _.forEach(props.getItemsData, eze=>{
            let ezel = eze;
            let fgh = _.find(props.getUserItemsData, {"Item": eze.Item});
            if (fgh) return;
            delete ezel.Discount;
            dataaas.push(ezel)
        });
        setProductsNotSet(dataaas)
        setDiscountPopup(true);
    };
    const discountPerCatPer = () => {
        const dataaas = [];
        _.forEach(props.getItemsData, eze=>{
            let ezel = eze;
            let fgh = _.find(props.getUserItemsData, {"Item": eze.Item});
            if (fgh) return;
            delete ezel.Discount;
            dataaas.push(ezel)
        });
        setGroups(Object.keys(_.groupBy(dataaas, "ItemGroupDescription")).map((e)=>{return {cat: e, ele: _.groupBy(dataaas, "ItemGroupDescription")[e]}}));
        setDiscountPopupCat(true);

    };
    /* ref.current.discountPerCat = discountPerCat;
    ref.current.discountPerCatPer = discountPerCatPer;
    ref.current.removeFromPriceList = removeFromPriceList; */
    const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
        ref.current.selectedRowKeys = selectedRowKeys;
        props.setStateUpdate()
        setState({
            prefix: null,
            selectedRowsData: selectedRowsData,
            selectedRowKeys
        });
    }

    return (
        <>
            <DataGrid
                dataSource={getLocItems}
                keyExpr="Item"
                ref={refDataGrid}
                onSelectionChanged={onSelectionChanged}
                selectedRowKeys={getState.selectedRowKeys}
                hoverStateEnabled
                sorting={{
                    mode: "multiple"
                }}
                showBorders={true}
                height={height-(45)}
                /* onRowExpanding={(e) => {
                    e.component.collapseAll(-1);
                }} */
                onRowUpdating={e=>{
                    e.cancel = new Promise( async (resolve, reject) => {
                        let bwr = [];
                        let kortingAmount = 0;
                        if (_.has(e.newData, 'Discount')) {
                            if (!(e.newData.Discount >= -100) || !(e.newData.Discount <= 100)) {
                                reject(new Error("Korting moet tussen -100 en 100 zijn"));
                                return;
                            };
                            //console.log(_.round(e.newData.Discount/100, 4));
                            kortingAmount = _.round(e.newData.Discount/100, 4);

                            const gfhg = {...e.oldData, ...e.newData, Discount:kortingAmount}
                            bwr = [{ updateOne :
                                {
                                "filter": {customerId: props.getAccountData.customerId, "userItems.Item": e.oldData.Item},
                                "update": { $set: { "userItems.$.Discount": Number(gfhg.Discount) } },
                                }
                            }];
                        }
                        if (_.has(e.newData, 'NewPrice')) {
                            /* if (!(e.newData.Discount >= -100) || !(e.newData.Discount <= 100)) {
                                reject(new Error("Korting moet tussen -100 en 100 zijn"));
                                return;
                            }; */
                            //console.log(_.round(e.newData.Discount/100, 4));
                            if (Number(e.newData.NewPrice) === 0) {
                                bwr = [{ updateOne :
                                    {
                                    "filter": {customerId: props.getAccountData.customerId, "userItems.Item": e.oldData.Item},
                                    "update": { $set: { "userItems.$.Discount": Number(1) } },
                                    }
                                }];
                                kortingAmount = 1;
                            } else {
                                let newSetter = 0
                                if (Number(e.newData.NewPrice)>e.oldData.BasePriceAmount) {
                                    let increase = Number(e.newData.NewPrice) - e.oldData.BasePriceAmount;
                                    newSetter = -_.round(increase / e.oldData.BasePriceAmount, 4)
                                } else {
                                    let decrease = e.oldData.BasePriceAmount - Number(e.newData.NewPrice);
                                    newSetter = _.round(decrease / e.oldData.BasePriceAmount, 4)
                                }
                                kortingAmount = newSetter;
                                bwr = [{ updateOne :
                                    {
                                    "filter": {customerId: props.getAccountData.customerId, "userItems.Item": e.oldData.Item},
                                    "update": { $set: { "userItems.$.Discount": Number(newSetter) } },
                                    }
                                }];
                            }

                        }
                        try {
                            await B2BAPI.post(`/d/b2bCustomerDatasV2`, bwr)
                            props.reloadAccountItems();
                            resolve();
                        } catch (error) {
                            console.error(error);
                            reject(new Error("Could not contact server"));
                        }

                    })
                }}
                rowAlternationEnabled
            >
                <Selection mode="multiple" selectAllMode="allPages" />
                <Scrolling mode="standard" />
                <Editing
                    mode="cell"
                    allowUpdating={true}
                />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                    allowedPageSizes={[50, 100, 200, 300]}
                    showInfo
                    showNavigationButtons
                    visible
                    showPageSizeSelector
                />
                {/* <Column dataField="status" caption="Status" allowEditing={false} width={55} cellComponent={StatusCell} alignment="center"/> */}
                <Column type={"selection"} width={35}/>
                <Column dataField="ItemCode" caption="SKU" allowEditing={false} width={130}/>
                <Column dataField="ItemDescription" caption="Omschrijving" allowEditing={false} sortOrder="asc"/>
                <Column dataField="BasePriceAmount" caption="Basis prijs" allowEditing={false} width={90} alignment="left" cellComponent={BasePriceAmountCell} />
                <Column dataField="Discount" caption="Korting" width={90} dataType="number" alignment="center" cellComponent={DiscountCell} />
                <Column dataField="NewPrice" caption="Nieuwe prijs" width={90} alignment="left" cellComponent={NewPriceCell} />
                <Column dataField="ItemGroupDescription" caption="Categorieën" allowEditing={false} visible={false} />
            </DataGrid>
            {getDiscountPopup && <Popup
                visible={getDiscountPopup}
                dragEnabled
                onHiding={()=>setDiscountPopup(false)}
                closeOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Producten toevoegen aan prijslijst"
            >
                <ScrollView width='100%' height='100%'>
                    <DataGrid
                        dataSource={getProductsNotSet}
                        showRowLines
                        onSaving={e=>{
                            e.cancel = true;
                            e.promise = new Promise( async (resolve, reject) => {
                                let bwr = [];

                                if (!_.every(e.changes, function(o) { return o.data.Discount <= 100; }) || !_.every(e.changes, function(o) { return o.data.Discount >= -100; })) {
                                    console.log("Korting moet tussen -100 en 100 zijn");
                                    reject(new Error("Korting moet tussen -100 en 100 zijn"));
                                    return;
                                };

                                _.forEach(e.changes, ele=> {
                                    if (ele.data.Discount === "") return;
                                    bwr.push({ updateOne :
                                        {
                                            "filter": {customerId: props.getAccountData.customerId},
                                            "update": { $addToSet: { "userItems": {Item:ele.key.Item, Discount: Number(_.round(ele.data.Discount/100, 4))} } },
                                        }
                                    })
                                });
                                try {
                                    await B2BAPI.post(`/d/b2bCustomerDatasV2`, bwr)
                                    props.reloadAccountItems();
                                    resolve();
                                    setDiscountPopup(false);
                                } catch (error) {
                                    console.error(error);
                                    reject(new Error("Could not contact server"));
                                }
                            })
                        }}
                    >
                        <SearchPanel visible
                            width={240}
                            placeholder="Zoeken..."
                        />
                        <GroupPanel visible={true} />
                        <Grouping autoExpandAll={false} />
                        <Paging defaultPageSize={100} />
                        <Pager
                            visible={true}
                            allowedPageSizes={[30, 50, 100, 200]}
                            showPageSizeSelector
                            showInfo
                            showNavigationButton />
                        <Editing
                            mode="batch"
                            allowUpdating={true}
                        />
                        <Column dataField="ItemCode" caption="SKU" allowEditing={false} width={130}/>
                        <Column dataField="ItemDescription" caption="Omscrijving" allowEditing={false}/>
                        <Column dataField="BasePriceAmount" caption="Basis prijs" allowEditing={false} width={80} alignment="center" cellComponent={BasePriceAmountCell} />
                        <Column dataField="Discount" caption="Korting" allowEditing width={80} cellComponent={DiscountCell2} alignment="center" />
                        <Column dataField="NewPrice" caption="Nieuwe prijs" allowEditing={false} width={80} alignment="center" cellComponent={NewPriceCell2} />
                        <Column dataField="ItemGroupDescription" caption="Categorieën" allowEditing={false} visible={false} groupIndex={0} />
                    </DataGrid>
                    <Position
                        at="center"
                        my="center"
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={{
                            text: 'Sluiten',
                            onClick: e=>{setDiscountPopup(false);getProductsNotSet([])}
                        }}
                    />
                </ScrollView>
            </Popup>}
            {getDiscountPopupCat && <Popup
                visible={getDiscountPopupCat}
                dragEnabled
                onHiding={()=>{setDiscountPopupCat(false);setGroups([])}}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title="Korting per categorie"
                width={500}
            >
                <DataGrid
                    dataSource={getGroups}
                    showRowLines
                    onSaving={e=>{
                        e.cancel = true;
                        e.promise = new Promise( async (resolve, reject) => {
                            let bwr = [];
                            if (!_.every(e.changes, function(o) { return o.data.Discount <= 100; }) || !_.every(e.changes, function(o) { return o.data.Discount >= -100; })) {
                                console.log("Korting moet tussen -100 en 100 zijn");
                                reject(new Error("Korting moet tussen -100 en 100 zijn"));
                                return;
                            };

                            _.forEach(e.changes, eleover=> {
                                const diser = Number(eleover.data.Discount)>0?_.round(Number(eleover.data.Discount)/100, 4):0
                                //console.log(eleover.data.Discount);
                                _.forEach(eleover.key.ele, ele=>{
                                    bwr.push({ updateOne :
                                        {
                                        "filter": {customerId: props.getAccountData.customerId},
                                        "update": { $addToSet: { "userItems": {Item:ele.Item, Discount: Number(diser)} } },
                                        }
                                    })
                                });
                            });
                            try {
                                await B2BAPI.post(`/d/b2bCustomerDatasV2`, bwr)
                                props.reloadAccountItems();
                                resolve();
                            } catch (error) {
                                console.error(error);
                                reject(new Error("Could not contact server"));
                            }
                        })
                    }}
                >
                    <Editing
                        mode="batch"
                        allowUpdating={true}
                    />
                    <Column dataField="cat" caption="Categorie" allowEditing={false}/>
                    <Column dataField="Discount" caption="Korting" allowEditing width={80} alignment="center" />
                </DataGrid>
                <Position
                    at="center"
                    my="center"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="before"
                    options={{
                        text: 'Sluiten',
                        onClick: ()=>{setDiscountPopupCat(false);setGroups([])}
                    }}
                />
            </Popup>}
        </>
    );
};

export default AccountSelected;