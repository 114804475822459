import B2BAPI from "api";
import { isEmpty, find } from "lodash";
import { __Items } from 'api/_exact/bulk';
import { __Items as __ItemsS } from "api/_exact/logistics";
import toast from 'react-hot-toast';
import moment from "moment";

export const getItemByID = async (ID) => {
    const errnf = "Gebruiker niet gevonden";
    const res = await B2BAPI.get(`/d/Items?ID=${ID}`);
    if (isEmpty(res)) throw errnf;
    return res.data[0]
}

export const getItemByCode = async (ID) => {
    const errnf = "Gebruiker niet gevonden";
    const res = await B2BAPI.get(`/d/Items?Code=${ID}`);
    if (isEmpty(res)) throw errnf;
    return res.data[0]
}

export const updateItem = async (ID, data) => {
    await __ItemsS("put", `(guid'${ID}')`, data)
    await B2BAPI.post('/d/Items', [{
        "updateOne": {
            "filter": {"ID": ID},
            "update": data
        }
    }])
}

export const upsertItem = async (ID, data) => {
    return await B2BAPI.post('/d/Items', [{
        "updateOne": {
            "filter": {"ID": ID},
            "update": data,
            "upsert": true
        }
    }])
}

export const removeItem = async (ID) => {
    const res = await B2BAPI.post('/d/Items', [{
        "deleteOne": {
            "filter": {"ID": ID}
        }
    }])
    return res.data;
}

export const getItems = async (from) => {
    const res = await B2BAPI.get('/d/Items?sort=Description')
    const res2 = await B2BAPI.get(`/d/btProducts`);
    //const res = await B2BAPI.get(`/Items?IsSalesItem=true&fields=SalesVatCode,ID,ItemGroupCode`);
    //const res = await B2BAPI.get(`/b2bCatagories?status=true&sort=sortNr`);
    //const res = await B2BAPI.get(`/b2bCustomerDatasV2?overId=b2bmain&fields=-userItems.__metadata`);
    return {
        items: res.data.map( e => {
            const finder = find(res2.data, {webshopId: e.Code});
            return { ...e, btproduct: finder ? finder : null }
        } ),
        btProducts: res2.data
    }
}

export const getMaterialsInventory = async (from) => {
    const res = await B2BAPI.get('/d/MaterialsInventory');
    //const res = await B2BAPI.get(`/Items?IsSalesItem=true&fields=SalesVatCode,ID,ItemGroupCode`);
    //const res = await B2BAPI.get(`/b2bCatagories?status=true&sort=sortNr`);
    //const res = await B2BAPI.get(`/b2bCustomerDatasV2?overId=b2bmain&fields=-userItems.__metadata`);
    return res.data;
}

export const getItemsFromExact = async () => {
    toast.info('Items update gestart');
    let bulkwright = [];
    return new Promise((resolve, reject) => {
        const ufe2 = async (params) => {
            const uri = params?params:`?$select=ID,AverageCost,Barcode,Class_01,Class_02,Class_03,Class_04,Class_05,Class_06,Class_07,Class_08,Class_09,Class_10,Code,CopyRemarks,CostPriceCurrency,CostPriceNew,CostPriceStandard,Created,Description,Division,EndDate,ExtraDescription,FreeBoolField_01,FreeBoolField_02,FreeBoolField_03,FreeBoolField_04,FreeBoolField_05,FreeDateField_01,FreeDateField_02,FreeDateField_03,FreeDateField_04,FreeDateField_05,FreeNumberField_01,FreeNumberField_02,FreeNumberField_03,FreeNumberField_04,FreeNumberField_05,FreeNumberField_06,FreeNumberField_07,FreeNumberField_08,FreeTextField_01,FreeTextField_02,FreeTextField_03,FreeTextField_04,FreeTextField_05,FreeTextField_06,FreeTextField_07,FreeTextField_08,FreeTextField_09,FreeTextField_10,GrossWeight,IsBatchItem,IsFractionAllowedItem,IsMakeItem,IsNewContract,IsOnDemandItem,IsPackageItem,IsPurchaseItem,IsSalesItem,IsSerialItem,IsStockItem,IsSubcontractedItem,IsTaxableItem,IsTime,IsWebshopItem,ItemGroup,ItemGroupCode,ItemGroupDescription,Modified,Modifier,ModifierFullName,NetWeight,NetWeightUnit,Notes,PictureName,PictureThumbnailUrl,PictureUrl,SalesVatCode,SalesVatCodeDescription,SearchCode,SecurityLevel,StartDate,Stock,Unit,UnitDescription,UnitType`;

            try {
                const e = await __Items('get', uri);
                const momentNow = Number(moment().format("YYYYMMDD"));
                const toUper = e.data.d.results.map(o=>{
                    let sd = Number(moment.unix(Number(o.StartDate.split("e(")[1].split(")")[0])/1000).format("YYYYMMDD"))
                    let ed = o.EndDate?Number(moment.unix(Number(o.EndDate.split("e(")[1].split(")")[0])/1000).format("YYYYMMDD")):null;
                    let status = true;
                    delete o.__metadata
                    if ((sd <= momentNow) && (ed?(ed>=momentNow):true)) {status=true} else {status=false}
                    return {
                        "updateOne" : {
                            "filter": {"ID": o.ID},
                            "update" : {
                                ...o,
                                Status: status,
                                StartDate: sd,
                                EndDate: ed
                            },
                            "upsert" : true
                        }
                    }
                })
                bulkwright = [...bulkwright, ...toUper];
                if (bulkwright.length > 0) {
                    if (e.data.d.__next) {
                        let next = "?$" + e.data.d.__next.split("?$").pop();
                        ufe2(next)
                    } else {
                        await B2BAPI.delete("/d/Items/remove");
                        await B2BAPI.post('/d/Items', bulkwright);
                        toast.success('Items update uitgevoerd');
                        resolve();
                    }
                } else {
                    toast.error('Kon data niet ophalen.');
                    reject('Kon data niet ophalen.');
                };
            } catch (error) {
                toast.error('Kon data niet ophalen.');
                reject('Kon data niet ophalen.');
            };
        };
        ufe2();
    });
};