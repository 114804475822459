import {
	Box, Grid, SimpleGrid, Text, useColorModeValue, Badge, Flex, Spacer, Button, HStack, Heading
} from '@chakra-ui/react';
import { useAuthUser } from 'react-auth-kit';
import React, { useState, useEffect } from 'react';
import { useMainStore } from "data";
import { find, forEach, isEmpty } from "lodash";
import B2BAPI from "api";
import Open from "./Open";

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function Home() {
	const auth = useAuthUser();
	const user = auth();
    const [getPageLoading, setPageLoading] = useState(true);
    const [getPlannedTrainings, setPlannedTrainings] = useState([]);
    const [getTrainingItems, setTrainingItems] = useState([]);
    const [getTraining, setTraining] = useState({});
	const t_listItem_bg = useColorModeValue("white", "gray.700");
	const [productionSchedulesGreenWeight, productionSchedulesCount] = useMainStore(state => [state.productionSchedulesGreenWeight, state.productionSchedulesCount]);

	const loadUsers = async () => {
        setPageLoading(true);
        const b2bTrainings = await B2BAPI.get(`/d/b2bTrainings?done!=true&sort=startDate`);
        const b2bmain = await B2BAPI.get(`/d/b2bCustomerDatasV2?overId=b2bmain`);
        const b2bItems = await B2BAPI.get(`/d/Items?ItemGroupCode=TRAIN`);
        const fixxex = [];

        forEach(b2bmain.data[0].userItems, eze=>{
            let fgh = find(b2bItems.data, {"ID": eze.Item});
            if (!fgh) return;
            if (!(fgh.ItemGroupCode==='TRAIN')) return;
            fixxex.push({...eze, ItemGroupDescription:fgh.ItemGroupDescription, ItemGroupCode:fgh.ItemGroupCode, SalesVatCode: fgh.SalesVatCode?fgh.SalesVatCode.trim():"1"})
        });

        setPlannedTrainings(b2bTrainings.data);
        setTrainingItems(fixxex);

        await timeout(500);
        setPageLoading(false);
        console.log(b2bTrainings);
    };

	useEffect(() => {
		loadUsers();
	}, []);
	if (!isEmpty(getTraining)) return (<><Open setTraining={setTraining} getTraining={getTraining} /></>)
	return (
		<Grid h="fit-content" maxW="100%" m="0 auto" px={{sm: "2vw", md: "5vw", lg: "7vw"}} py={4}>
			<Flex>
				<Box pb='4'>
					<HStack>
						<Heading fontSize={"1.4rem"} noOfLines={1}>
							Planning
						</Heading>
					</HStack>

				</Box>
				{/* <Spacer />
				<Box pb='4'>
					<Button onClick={()=>{setOpenData({
						standardPlaces: 1
					});onOpen()}}>Toevoegen</Button>
				</Box> */}
			</Flex>
			<SimpleGrid columns={{md: 1, lg: 2}} spacing='20px'>
				{getTrainingItems.map( e =>
					<Box borderRadius={"lg"} bgColor={t_listItem_bg} scale w="100%" py={8} px={8} key={e.Item} _hover={{ filter: "brightness(90%)", cursor: "pointer", bg: t_listItem_bg }} onClick={()=>{setTraining(e)}} >
						<Text fontSize={"1.4rem"}>{e.ItemDescription}</Text>
						<Badge className="me-2" bg='primary'>Geplande workshops: {getPlannedTrainings.filter(x=>x.trainingCode===e.ItemCode).length}</Badge>
					</Box>
				)}
			</SimpleGrid>
		</Grid>
	);
};

export default Home;
