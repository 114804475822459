import { CloseIcon, HamburgerIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import {
	Avatar, Box, Button, Center, Flex, HStack, IconButton, Link, Menu,
	MenuButton, MenuDivider, MenuItem, MenuList, Stack, Text, useColorMode, useColorModeValue, useDisclosure, VStack
} from '@chakra-ui/react';
import { ReactComponent as Logo } from 'assets/svg/logosvg.svg';
import { useMainStore } from "data";
import { useSignOut, useAuthUser } from 'react-auth-kit';
import { NavLink as RNavLink, useHistory, useLocation } from 'react-router-dom';


const NavLink = ({ children, to }) => {
	const { pathname } = useLocation();
	const bgc = useColorModeValue('green.800', 'green.800');
	return (

	<Link
		color={useColorModeValue('gray.100', 'gray.200')}
		as={RNavLink}
		px={2}
		py={1}
		rounded={'md'}
		_hover={{
			textDecoration: 'none',
			bg: bgc,
		}}
		bg={(pathname===to)&&bgc}
		to={to}
	>
		{children}
	</Link>
)};

export default function WithAction() {
	const signOut = useSignOut()
	const auth = useAuthUser();
	const user = auth()
	const history = useHistory();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { colorMode, toggleColorMode } = useColorMode();

	const Links = [
		{name: 'Dashboard', href: "/"},
		{name: `Planning`, href: "/planning"},
		{name: `Reserveringen`, href: "/reserveringen"},

	];
	/* const Links = [{name: `Accounts`, href: "/accounts"},
		{name: `Probat (${productionSchedulesCountProbat})`, href: "/roasting/probat"},
		{name: 'Dashboard', href: "/"},
		{name: `Orders (${webShopOrdersAmount+wholesaleOrdersAmount})`, href: "/orders"},
		{name: `Shipping (${0})`, href: "/shipping"},
		{name: `Archief`, href: "/roasting/probat"}
	]; */
	return (
		<>
			<Box bg={useColorModeValue('green.400', 'green.900')} px={{sm: "2vw", md: "5vw", lg: "7vw"}} w="100%">
				<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
					<IconButton
						size={'md'}
						icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
						aria-label={'Open Menu'}
						display={{ md: 'none' }}
						onClick={isOpen ? onClose : onOpen}
					/>
					<HStack spacing={8} alignItems={'center'}>
						<Box color={useColorModeValue('white', 'gray.100')}>
							<HStack spacing={0}>
								<Logo
									style={{
										height:'32px',
										width: '32px',
										marginRight: '5px'
									}}
								></Logo>
								<VStack spacing={0} align={"left"}>
									<Text fontSize={"mg"} fontWeight={"extrabold"} display={{ sm: 'none', md: 'block' }}>BOCCA</Text>
									<Text fontSize={"sm"} fontWeight={"medium"} display={{ sm: 'none', md: 'block' }}>Workshops - Admin</Text>
									<Text fontSize={"sm"} fontWeight={"extrabold"} display={{ sm: 'block', md: 'none' }}>BOCCA</Text>
									<Text fontSize={"xx-small"} fontWeight={"medium"} display={{ sm: 'block', md: 'none' }}>Workshops - Admin</Text>
								</VStack>
							</HStack>
						</Box>
						<HStack
							as={'nav'}
							spacing={4}
							display={{ base: 'none', md: 'flex' }}>
							{Links.map(({name, href}) => (
								<NavLink key={name} to={href} ><Text fontSize={"medium"} fontWeight={"bold"}>{name}</Text></NavLink>
							))}
						</HStack>
					</HStack>
					<Flex alignItems={'center'}>
						<Stack direction={'row'} spacing={7}>
							<Button onClick={toggleColorMode} bg={useColorModeValue('green.700', 'green.800')} _hover={{bg: useColorModeValue('green.800', 'green.700')}} display={{ sm: 'none', md: 'block' }}>
								{colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
							</Button>

							<Menu>
								<MenuButton
									as={Button}
									rounded={'full'}
									variant={'link'}
									cursor={'pointer'}
									minW={0}>
									<Avatar
										size={'sm'}
										src={'https://avatars.dicebear.com/api/male/username.svg'}
									/>
								</MenuButton>
								<MenuList alignItems={'center'}>
									<br />
									<Center>
										<Avatar
											size={'2xl'}
											src={'https://avatars.dicebear.com/api/male/username.svg'}
										/>
									</Center>
									<br />
									<Center>
										<b>{user?.displayName}</b>
									</Center>
									<Center>
										<p>{user?.email}</p>
									</Center>
									<br />
									<MenuDivider />
									{/* <MenuItem>Your Servers</MenuItem> */}
									<MenuItem onClick={toggleColorMode} >{colorMode === 'light' ? <MoonIcon me={2} /> : <SunIcon me={2} />} {colorMode === 'light' ? "Dark mode" : "Light mode"}</MenuItem>
									<MenuDivider />
									<MenuItem onClick={async e=>{
										signOut();
										history.push("/");
									}}>Logout</MenuItem>
								</MenuList>
							</Menu>
						</Stack>
					</Flex>
				</Flex>

				{isOpen ? (
					<Box pb={4} display={{ md: 'none' }}>
						<Stack as={'nav'} spacing={4}>
							{Links.map(({name, href}) => (
								<NavLink key={name} to={href}><Text fontSize={"medium"} fontWeight={"bold"}>{name}</Text></NavLink>
							))}
						</Stack>
					</Box>
				) : null}
			</Box>
		</>
	);
}