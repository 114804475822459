import axios from 'axios';

const b2bApi = axios.create({
	baseURL: `https://apiv2.beantrack.app/api/b2bv1`,
	timeout: 128000,
	headers: { Authorization: "Bearer "+process.env.REACT_APP_BEANTRACK_KEY }
});

export const __newOrderMailer = data => {
    return b2bApi.post(`/mailer/newOrder`, data);
};

export const  BEANAPI = axios.create({
	baseURL: `https://api.beantrack.app/api/v1`,
	timeout: 128000,
	headers: {
		'Content-Type': "application/json",
		'Authorization': "Bearer "+process.env.REACT_APP_BEANTRACK_KEY
	}
});
export const  BOCCASQL = axios.create({
	baseURL: `https://sql.bocca.app/api/v1`,
	timeout: 128000,
	headers: {
		'Content-Type': "application/json",
		'Authorization': "Bearer "+process.env.REACT_APP_BEANTRACK_KEY
	}
});
export const boccaAipUrl = "https://api.bocca.app/api/v1"
export const boccaApi = axios.create({
	baseURL: boccaAipUrl,//https://api.bocca.app/api/v1
	timeout: 128000,
	headers: {
		'Content-Type': "application/json",
		'Authorization': "Bearer "+process.env.REACT_APP_BEANTRACK_KEY
	}
});
export const CROP = (t, uri, data) => {
    switch (t) {
        case 'get':
            return BEANAPI.get('/crop/proxy/get/'+uri);
        case 'post':
            return BEANAPI.post('/crop/proxy/post/'+uri, data);
        case 'put':
            return BEANAPI.put('/crop/proxy/patch/'+uri, data);
        case 'delete':
            return BEANAPI.delete('/crop/proxy/delete/'+uri);
        default:
            break;
    }
};

export default b2bApi