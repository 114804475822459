import BEANAPI from './beanApi';
import { curdiv } from "./config";

export const __Accounts = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/CRM/Accounts`;
	return BEANAPI.get(baseUri + uri);
};

export const __Addresses = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/CRM/Addresses`;
	return BEANAPI.get(baseUri + uri);
};

export const __Items = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/Logistics/Items`;
	return BEANAPI.get(baseUri + uri);
};

export const __SalesItemPrices = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/Logistics/SalesItemPrices`;
	return BEANAPI.get(baseUri + uri);
};

export const __SalesInvoices = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/SalesInvoice/SalesInvoices`;
	return BEANAPI.get(baseUri + uri);
};

export const __SalesInvoiceLines = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/SalesInvoice/SalesInvoiceLines`;
	return BEANAPI.get(baseUri + uri);
};

export const __GoodsDeliveries = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/SalesOrder/GoodsDeliveries`;
	return BEANAPI.get(baseUri + uri);
};

export const __GoodsDeliveryLines = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/SalesOrder/GoodsDeliveryLines`;
	return BEANAPI.get(baseUri + uri);
};

export const __SalesOrderLines = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/SalesOrder/SalesOrderLines`;
	return BEANAPI.get(baseUri + uri);
};

export const __SalesOrders = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/SalesOrder/SalesOrders`;
	return BEANAPI.get(baseUri + uri);
};

export const __Documents = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/Documents/Documents`;
	return BEANAPI.get(baseUri + uri);
};
export const __DocumentAttachments = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/bulk/Documents/DocumentAttachments`;
	return BEANAPI.get(baseUri + uri);
};