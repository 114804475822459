import React from 'react';
import {
	Flex
} from '@chakra-ui/react';
import "../assets/css/css.css";

//components
import Navbar from "./Navbar"
//import Header from "./Header"
//import Footer from "./Footer"
//import RightSidebar from "../CommonForBoth/RightSidebar"
const Layout = (props) => {

	return (
		<>
			<div id="layout-wrapper">
				<Flex className="main-header" as="header" position="fixed" w="100%">
					<Navbar />
				</Flex>{/*
				<Container as="main" mt="20">
					{props.children}
				</Container> */}
				<div className="main-content">
					{props.children}
				</div>
				{/* <Footer /> */}
			</div>
		</>
	);
}

export default Layout;