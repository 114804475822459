import {
	Box, Grid, SimpleGrid, Text, useColorModeValue, chakra, Flex, Icon,StatLabel, Stat, StatHelpText, StatNumber, Divider, Button
} from '@chakra-ui/react';
import { ReactComponent as CoffeeBeen } from 'assets/svg/coffee-been.svg';
import { ReactComponent as Calendar } from 'assets/svg/monthly-calendar.svg';
import { useAuthUser } from 'react-auth-kit';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMainStore } from "data";
import B2BAPI from "api";

function Home() {
	const auth = useAuthUser();
	const user = auth()
	const t_listItem_bg = useColorModeValue("white", "gray.700");
    const [getBooked, setBooked] = useState(0);
    const [getPlanned, setPlanned] = useState(0);

	const loadBooked = async () => {
        const epost = await B2BAPI.get(`/d/b2bBookedTrainings?sort=-orderDate&limit=200&status=420`)//,421
        const epost2 = await B2BAPI.get(`/d/b2bBookedTrainings?sort=-orderDate&limit=200&status=410`)//,421
        setBooked(epost.data.length);
		setPlanned(epost2.data.length);
    };

	React.useEffect(() => {
		loadBooked()
	}, []);

	return (
		<Grid h="fit-content" maxW="100%" m="0 auto" px={{sm: "2vw", md: "5vw", lg: "7vw"}} py={8}>
			<Box  w="100%" py={8} px={8} mb={8}>
				<Text fontSize={"1.8rem"}>Welkom terug {user?.name}!</Text>
				{user?.email}
			</Box>
			<Divider  mb={8}/>
			<SimpleGrid w="100%" columns={{ base: 1, md: 1, lg: 1 }} spacing={{ base: 4, md: 20}}>
				<Box borderRadius={"lg"} bgColor={t_listItem_bg} w="100%" py={8} px={8} shadow="md">
					<Text fontSize={"1.4rem"} fontWeight={600}>Reserveringen</Text>
					<Text fontSize={"1rem"}>{getBooked} - <small>Nieuw</small></Text>
					<Text fontSize={"1rem"}>{getPlanned} - <small>Ingepland</small></Text>
					<Button as={Link} to={"/planning"} mt={2} size={"sm"}>Meer...</Button>
				</Box>
			</SimpleGrid>
		</Grid>

	);
}
const Feature = (props) => {
    return (
		<Stat>
			<StatLabel fontWeight={600} fontSize={"1.4rem"}>{props.title}</StatLabel>
			<StatNumber>{props.amount}</StatNumber>
			{/* <StatHelpText>Feb 12 - Feb 28</StatHelpText> */}
		</Stat>
    );
  };
export default Home;
