import {
	Alert,
	AlertIcon, Box, Button, Flex, FormControl,
	FormLabel, Heading, Input, Link, Stack, useColorModeValue, VStack, Text
} from '@chakra-ui/react';
import { useSignIn } from 'react-auth-kit';
import { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";//

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

export default function SimpleCard() {
	const signIn = useSignIn();
	const history = useHistory();
	const [getError, setError] = useState(false);
	const [getLogin, setLogin] = useState(false);
	const pagebg = useColorModeValue('gray.50', 'gray.800')
	const boxbg = useColorModeValue('white', 'gray.700')

	const login = async (email, password) => {
		try {
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email:email.trim().toLowerCase(), password:password.trim() })
			};
			const responser = await fetch(`https://api.bocca.app/api/v1/auth/login`, requestOptions);
			const user = await handleResponse(responser);
			let tmpUser = {...user.user};
			delete tmpUser.password;
			console.log(tmpUser);
			if (!tmpUser.superAdmin) { setError("Alleen beschikbaar voor bocca medewerkers"); return }
			if (!tmpUser.status) { setError("Deze account is niet actief"); return }
			if (signIn({token: user.access_token, expiresIn:43200, tokenType: "Bearer", authState: tmpUser})) {
				//await dataLoader(tmpUser)
				setTimeout(() => {
					history.push("/");
				}, 500);

			} else {
				setError("Je email en/of wachtwoord klopt niet.");
			}
			setLogin(true)
		} catch (error) {
			//const errorCode = error.code;
			//const errorMessage = error.message;
			setError("Je email en/of wachtwoord klopt niet.");
		};
	}
	if (getLogin) return ( <Redirect to="/" /> )

	return (
		<Flex
			minH={'100vh'}
			align={'center'}
			justify={'center'}
			bg={pagebg}
		>

			<Stack spacing={8} mx={'auto'}minW={"30vw"} maxW={'lg'} py={12} px={6}>
				<Stack align={'center'}>
					<VStack spacing={0} mb={8}>
						<Text fontSize={"x-large"} fontWeight={"extrabold"}>BOCCA</Text>
						<Text fontSize={"large"} fontWeight={"medium"}>Workshops - Admin</Text>
					</VStack>
					{getError ? <Alert status='error'>
						<AlertIcon />
						{getError}
					</Alert> : null}
					{/* <Text fontSize={'lg'} color={'gray.600'}>
						to enjoy all of our cool <Link color={'blue.400'}>features</Link> ✌️
					</Text> */}
				</Stack>
				<Box
					rounded={'lg'}
					bg={boxbg}
					boxShadow={'lg'}
					p={8}
				>
					<form onSubmit={e=>{e.preventDefault();login(e.target[0].value, e.target[1].value)}}>
						<Stack spacing={4}>
					<Heading fontSize={'lg'} mb={8}>
						Log-in om verder te gaan
					</Heading>
								<FormControl id="email">
									<FormLabel>Email adres</FormLabel>
									<Input type="email" />
								</FormControl>
								<FormControl id="password">
									<FormLabel>Wachtwoord</FormLabel>
									<Input type="password" />
								</FormControl>
								<Stack spacing={10}>
									<Stack
										direction={{ base: 'column', sm: 'row' }}
										align={'start'}
										justify={'space-between'}
									>
										<Link href='/forget-password' color={'blue.400'}>Wachtwoord vergeten?</Link>
									</Stack>
									<Button
										bg={'blue.400'}
										color={'white'}
										_hover={{
											bg: 'blue.500',
										}}
										type="submit"
									>
										Log-in
									</Button>
								</Stack>
						</Stack>
					</form>
				</Box>
			</Stack>
		</Flex>
	);
}