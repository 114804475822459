import BEANAPI from './beanApi';
import { curdiv } from "./config";

export const __AccountItems = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/read/logistics/AccountItems`;
	return BEANAPI.get(baseUri + uri);
};

export const __CustomerItems = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/logistics/CustomerItems`;
	return BEANAPI.get(baseUri + uri);
};

export const __ItemAssortment = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/logistics/ItemAssortment`;
	return BEANAPI.get(baseUri + uri);
};

export const __ItemAssortmentProperty = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/logistics/ItemAssortmentProperty`;
	return BEANAPI.get(baseUri + uri);
};

export const __ItemChargeRelation = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/logistics/ItemChargeRelation`;
	return BEANAPI.get(baseUri + uri);
};

export const __ItemDetailsByID = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/read/logistics/ItemDetailsByID`;
	return BEANAPI.get(baseUri + uri);
};

export const __ItemExtraField = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/read/logistics/ItemExtraField`;
	return BEANAPI.get(baseUri + uri);
};

export const __ItemGroups = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/logistics/ItemGroups`;
	return BEANAPI.get(baseUri + uri);
};

export const __Items = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/logistics/Items`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};
export const __SalesItemPrices = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/logistics/SalesItemPrices`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};

export const __StockPosition = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/read/logistics/StockPosition`;
	return BEANAPI.get(baseUri + uri);
};

export const __SupplierItem = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/logistics/SupplierItem`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};

export const __Units = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/logistics/Units`;
	return BEANAPI.get(baseUri + uri);
};