import B2BAPI from "api";
import { isEmpty, find } from "lodash";
import { __Accounts } from 'api/_exact/bulk';
import toast from 'react-hot-toast';
import moment from "moment";

export const getAccountByID = async (ID) => {
    const errnf = "Gebruiker niet gevonden";
    const res = await B2BAPI.get(`/d/Accounts?ID=${ID}`);
    if (isEmpty(res)) throw errnf;
    return res.data[0]
}

export const updateAccount = async (ID, data) => {
    const res = await B2BAPI.post('/d/Accounts', [{
        "updateOne": {
            "filter": {"ID": ID},
            "update": data
        }
    }])
    return res.data;
}

export const upsertAccount = async (ID, data) => {
    const res = await B2BAPI.post('/d/Accounts', [{
        "updateOne": {
            "filter": {"ID": ID},
            "update": data,
            "upsert": true
        }
    }])
    return res.data;
}

export const removeAccount = async (ID) => {
    const res = await B2BAPI.post('/d/Accounts', [{
        "deleteOne": {
            "filter": {"ID": ID}
        }
    }])
    return res.data;
}

export const deleteAppAccount = async (ID) => {
    const res = await B2BAPI.post('/d/b2bCustomerDatasV2', [{
        "deleteOne": {
            "filter": {"customerId": ID}
        }
    }])
    return res.data;
}

export const getAccounts = async () => {
    const res = await B2BAPI.get('/d/Accounts?sort=Name&fields=Status,Code,ID,Name,Classification1,Classification2,Classification3,Classification4,Classification5,Classification6,Classification8,ActiveStatus');
    const accountClass = await B2BAPI.get(`/d/AccountClassifications`)
    const appAccounts = await B2BAPI.get(`/customerData?fields=customerId`);
    const accs = res.data.map( o => {
        return {
            ...o,
            Code: o.Code.trim(),
            Classification1: find(accountClass.data, ['ID', o.Classification1])?.Description||"",
            Classification2: find(accountClass.data, ['ID', o.Classification2])?.Description||"",
            Classification3: find(accountClass.data, ['ID', o.Classification3])?.Description||"",
            Classification4: find(accountClass.data, ['ID', o.Classification4])?.Description||"",
            Classification5: find(accountClass.data, ['ID', o.Classification5])?.Description||"",
            Classification6: find(accountClass.data, ['ID', o.Classification6])?.Description||"",
            Classification8: find(accountClass.data, ['ID', o.Classification8])?.Description||"",
            hasAccount: find(appAccounts.data, ['customerId', o.ID])?true:false,
        }
    })
    return accs;
}

export const getAccountsFromExact = async () => {
    toast.info('Accounts update gestart');
    let bulkwright = [];
    return new Promise((resolve, reject) => {
        const ufe = async (params) => {
            const uri = params?params:`?$select=ID,Accountant,AccountManager,AccountManagerFullName,AccountManagerHID,ActivitySector,ActivitySubSector,AddressLine1,AddressLine2,AddressLine3,Blocked,BusinessType,CanDropShip,City,Classification,Classification1,Classification2,Classification3,Classification4,Classification5,Classification6,Classification7,Classification8,Code,Country,CountryName,CreditLinePurchase,CreditLineSales,DiscountPurchase,DiscountSales,Division,Email,EndDate,InvoiceAccount,InvoiceAccountCode,InvoiceAccountName,InvoiceAttachmentType,InvoicingMethod,MainContact,Name,Parent,Phone,PhoneExtension,Postcode,PriceList,PurchaseCurrency,PurchaseCurrencyDescription,PurchaseVATCode,PurchaseVATCodeDescription,Remarks,Reseller,ResellerCode,SalesCurrency,SalesCurrencyDescription,SearchCode,StartDate,State,StateName,Status,Type`;
            try {
                const e = await __Accounts('get', uri);
                bulkwright = [...bulkwright, ...e.data.d.results];
                if (bulkwright.length > 0) {
                    if (e.data.d.__next) {
                        let next = "?$" + e.data.d.__next.split("?$").pop();
                        ufe(next)
                    } else {
                        const momentNow = Number(moment().format("YYYYMMDD"));
                        const toUper = bulkwright.filter(o=> o.Status === "C").map(o=>{
                            let sd = Number(moment.unix(Number(o.StartDate.split("e(")[1].split(")")[0])/1000).format("YYYYMMDD"))
                            let ed = o.EndDate?Number(moment.unix(Number(o.EndDate.split("e(")[1].split(")")[0])/1000).format("YYYYMMDD")):null;
                            let status = true;
                            if ((sd <= momentNow) && (ed?(ed>=momentNow):true)) {status=true} else {status=false}

                            return {
                                "insertOne" : {
                                    "document" : {
                                        ...o,
                                        ActiveStatus: status,
                                        StartDate: sd,
                                        EndDate: ed
                                    }
                                }
                            }
                        })
                        //console.log(toUper);
                        await B2BAPI.delete("/d/Accounts/remove");
                        await B2BAPI.post('/d/Accounts', toUper.filter(o=> o.insertOne.document.ActiveStatus === true))
                        //console.log(toUper.filter(o=> o.ActiveStatus === true));
                        toast.success('Accounts update uitgevoerd');
                        resolve();
                    }
                } else {
                    toast.error('Kon data niet ophalen.');
                    reject('Kon data niet ophalen.');
                };
            } catch (error) {
                toast.error('Kon data niet ophalen.');
                reject('Kon data niet ophalen.');
            };
        };
        ufe();
    });
};