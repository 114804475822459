import BEANAPI from './beanApi';
import { curdiv } from "./config";

export const __InvoiceSalesOrders = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/salesinvoice/InvoiceSalesOrders`;
	return BEANAPI.post(baseUri + uri, data);
};

export const __Layouts = (type, uri) => {
	const baseUri = `/exact/${type}/${curdiv}/salesinvoice/Layouts`;
	return BEANAPI.get(baseUri + uri);
};

export const __PrintedSalesInvoices = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/salesinvoice/PrintedSalesInvoices`;
	return BEANAPI.post(baseUri + uri, data);
};

export const __SalesInvoiceLines = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/salesinvoice/SalesInvoiceLines`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};

export const __SalesInvoices = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/salesinvoice/SalesInvoices`;
	switch (type) {
		case 'get':
			return BEANAPI.get(baseUri + uri);
		case 'post':
			return BEANAPI.post(baseUri + uri, data);
		case 'put':
			return BEANAPI.put(baseUri + uri, data);
		case 'delete':
			return BEANAPI.delete(baseUri + uri);
		default:
			break;
	}
};

export const __SalesOrderID = (type, uri, data) => {
	const baseUri = `/exact/${type}/${curdiv}/salesinvoice/SalesOrderID`;
	return BEANAPI.post(baseUri + uri, data);
};