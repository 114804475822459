import {
	ChakraProvider, useColorModeValue
} from '@chakra-ui/react';
import theme from "assets/theme";
import { useIsAuthenticated } from 'react-auth-kit';
import React, { useEffect } from "react";
import { Toaster } from 'react-hot-toast';
// App.tsx, { useEffect }
//import React from 'react';, useHistory
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ForgetPassword from "./pages/Auth/ForgetPassword";
import Login from "./pages/Auth/Login";
import Home from "./pages/Home";
import Planning from "./pages/Planning";
import History from "./pages/History";
import Accounts from "./pages/Accounts";
import AccountOpen from "./pages/AccountOpen";
// layouts Format
import MainLayout from "./templates/main";
import { useMainStore } from "data";
import { locale, loadMessages } from 'devextreme/localization';
import nlMessages from 'devextreme/localization/messages/nl.json';
import moment from 'moment';
import themes from "devextreme/ui/themes";

loadMessages(nlMessages);
locale("nl");
moment.locale("nl")

function AuthRoute({ component: Component, children, ...rest }) {
	//const [initAccounts] = useMainStore(state => [state.initAccounts]);
	const isAuthenticated = useIsAuthenticated();
	let tablecolor = useColorModeValue("generic.light", "generic.dark");

	themes.current(tablecolor);

	/* useEffect(() => {
		if (isAuthenticated()) initAccounts();
	}, []) */

	if (!isAuthenticated()) {
		return <Redirect to="/login" />;
	}


	return (
		<Route
      		{...rest}
      		render={ props => {
				if (!isAuthenticated()) {
					return (
						<Redirect
							to={{ pathname: "/login", state: { from: props.location } }}
						/>
					)
				}

				return (
					<MainLayout>
						<Component {...props} />
					</MainLayout>
				)
			}}
    	/>
	);
}

function App() {
	/* const history = useHistory();

	const handleLogin = () => {
		history.push('/');
	};

	const handleLogout = () => {
		history.push('/login');
	}; */

	return (
	<ChakraProvider theme={theme}>
		<Toaster
			position="top-right"
			reverseOrder={false}
			gutter={8}
			containerClassName=""
			containerStyle={{}}
			toastOptions={{
				// Define default options
				className: '',
				success: {
				  	style: {
						color: "white",
						background: useColorModeValue('#2F855A', '#22543D'),
				  	},
				},
				error: {
					duration: 3000,
				  	style: {
						color: "white",
						background: useColorModeValue('#C53030', '#822727'),
				  	},
				},
				warning: {
				  style: {
					color: "white",
					background: useColorModeValue('#B7791F', '#975A16'),
				  },
				},
				info: {
				  style: {
					color: "white",
					background: useColorModeValue('#2b6cb0', '#2c5282'),
				  },
				},
				duration: 3000
			}}
			/>
		<Router>
			<Switch>
				<Route path="/login" exact component={Login} />
				<Route path="/forget-password" exact component={ForgetPassword} />

				<AuthRoute path="/" exact component={Home} />
				<AuthRoute path="/planning" component={Planning} />
				<AuthRoute path="/reserveringen" component={History} />
				<AuthRoute path="/accounts" component={Accounts} />
				<AuthRoute path="/account/:accountID?/:pageName?" component={AccountOpen} />

			</Switch>
		</Router>
    </ChakraProvider>
	);
}

export default App