import React from "react"
import {
	Box, Grid, SimpleGrid, Text, useColorModeValue, Badge, Flex, Spacer, Button, Modal, ModalOverlay,ModalContent,ModalHeader,ModalCloseButton,ModalBody,FormControl,FormLabel,Input,ModalFooter,useDisclosure,HStack,useNumberInput, Heading, Divider
} from '@chakra-ui/react';

const EcommerceOrdersModal = props => {
	const { isOpen, toggle } = props

	const statusRet = (e) => {

		switch (e) {
			case 420:
				return <Badge variant='subtle' colorScheme='orange'>Nieuw</Badge>;
			case 410:
				return <Badge variant='subtle' colorScheme='blue'>Ingepland</Badge>;
			case 421:
				return <Badge variant='subtle' colorScheme='green'>Gefactureerd</Badge>;
			case 400:
				return <Badge variant='subtle' colorScheme='red'>Geannuleerd</Badge>;
			default:
				break;
		}
	};
	React.useEffect(() => {
		console.log(props.modalData);
	}, [props.modalData]);
	return (
		<Modal
			isOpen={isOpen}
			isCentered
			>
			<ModalOverlay backdropFilter='blur(10px)' />
			<ModalContent>
				<ModalHeader>Reservering overzicht</ModalHeader>
				<Divider />
				<ModalBody py={4}>
                        <HStack mb={2}>
							<Text fontWeight={600}>Status:</Text><span>{statusRet(props.modalData.status)}</span>
						</HStack>
						<HStack mb={2}>
							<Text fontWeight={600}>Klant:</Text><span>{props.modalData.contactName}</span>
						</HStack>
						<Box mb={2}>
							<Text fontWeight={600}>Workshop:</Text>
                            <Box ms={2}>
								{props.modalData.trainingName}<br />
                                {props.modalData.selectedDate.dateformated}
                            </Box>
						</Box>
						<Box mb={2}>
							<Text fontWeight={600}>Deelnemers:</Text>
                            <Box ms={2}>
								{props.modalData.deelnemers.map((z, i)=><Box key={i}><Text mb={2}>{z[0]}<br />{z[1]}<br />{z[2]}</Text><Divider mb={2} variant={"dashed"} /></Box>)}
                            </Box>
						</Box>
				</ModalBody>
				<Divider />
				<ModalFooter>
					<Button mr={3} onClick={toggle}>
						Sluit
					</Button>
					{!(props.modalData.status===421)&&!(props.modalData.status===400)&&<><div className='vRule'></div><Button mr={3} colorScheme={"red"} onClick={e=>props.removeOrder(props.modalData)} disabled={props.getLoadIndicatorVisible}>

                        Annuleren
                    </Button></>}
					{!(props.modalData.status===400)&&!(props.modalData.status===421)&&<>
                    {props.modalData.status===420&&<><div className='vRule'></div>
					<Button mr={3} colorScheme={"blue"} onClick={e=>props.confirmOrder(props.modalData)} disabled={props.getLoadIndicatorVisible}>

                        Ingepland
                    </Button></>}
					{props.modalData.status===410&&<><div className='vRule'></div>
                    <Button colorScheme={"blue"} onClick={e=>props.submitOrder(props.modalData)} disabled={props.getLoadIndicatorVisible}>

                        Factureren
                    </Button></>}</>}
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default EcommerceOrdersModal
